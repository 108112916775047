import React, { useEffect, useState } from "react"
import styled from "styled-components"

const TOC = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-left: 1rem;
  width: 85%;

  li {
    :hover,
    :focus {
      a {
        font-weight: 600;
        transition: all 50ms ease-in-out;
      }
    }
  }

  li > a {
    color: #222222;
    font-family: "Poppins", "Futura PT", -apple-system, "BlinkMacSystemFont",
      "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    text-rendering: optimizeLegibility;
    font-weight: 500;
    letter-spacing: normal;
    font-size: 1.125rem;
    line-height: 1.75;
    text-decoration: none;
  }

  li:last-child {
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }
`

// font-size: 1.25rem;
// line-height: 2.125rem;

const Indicator = styled.div`
  background-color: #1b5998;
  content: "";
  display: inline-block;
  height: 0.625rem;
  position: relative;
  vertical-align: middle;
  width: 50%;
  right: 1em;
  margin-left: -50%;
  border-radius: 30px;
  top: -2px;
`

function getIds(items) {
  return items.reduce((acc, item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item.url.slice(1))
    }
    if (item.items) {
      acc.push(...getIds(item.items))
    }
    return acc
  }, [])
}

function useActiveId(itemIds) {
  const [activeId, setActiveId] = useState(itemIds[0])
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      { rootMargin: `0% 0% -90% 0%` },
      { threshold: 1.0 }
    )
    itemIds.forEach(id => {
      observer.observe(document.getElementById(id))
    })
    return () => {
      itemIds.forEach(id => {
        observer.unobserve(document.getElementById(id))
      })
    }
  }, [itemIds])
  return activeId
}

const renderItems = (items, activeId) => {
  return (
    <TOC>
      {items.map(item => {
        return (
          <li style={{ marginBottom: 15 }} key={item.url}>
            {activeId === item.url.slice(1) && <Indicator />}
            <a
              href={item.url}
              style={{
                color: activeId === item.url.slice(1) ? "#1b5998" : "#222222",
                fontWeight: activeId === item.url.slice(1) && 600,
              }}
            >
              {item.title}
            </a>
            {item.items && renderItems(item.items, activeId)}
          </li>
        )
      })}
    </TOC>
  )
}

function TableOfContents(props) {
  const idList = getIds(props.items)
  const activeId = useActiveId(idList)

  return <>{renderItems(props.items, activeId)}</>
}
export default TableOfContents
