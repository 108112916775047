import { MDXProvider } from "@mdx-js/react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import styled from "styled-components"
import * as Containers from "../components/Containers"
import Layout from "../components/layout"
import TOC from "../components/toc"
import Email from "../images/Email_v2.inline.svg"

const H1 = props => <h1 style={{ marginBottom: 30 }} {...props} />

const H3 = props => <h3 style={{ marginBottom: 30 }} {...props} />

const Paragraph = props => <p {...props} />

const components = {
  h1: H1,
  h3: H3,
  p: Paragraph,
}

const MDXLayout = ({ data }) => {
  const { body, frontmatter, tableOfContents } = data.mdx
  return (
    <Layout>
      <PageContainer>
        <Containers.SectionContainer style={{ height: "100%" }}>
          <Container>
            <TOCContainer>
              {tableOfContents?.items && (
                <TOCContent>
                  <TOC items={tableOfContents.items} />
                </TOCContent>
              )}
            </TOCContainer>
            <ContentContainer>
              <h1>{frontmatter.title}</h1>
              <br />
              <p style={{ fontStyle: "italic" }}>
                Last updated on {frontmatter.date}
              </p>
              <br />
              <MDXProvider components={components}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </ContentContainer>
          </Container>
        </Containers.SectionContainer>
        {frontmatter.contact && (
          <InfoContainer>
            <Email style={{ width: "10rem", height: "10rem" }} />
            <h5>Contact Information</h5>
            <p>
              For general queries, including partnership opportunities, please
              contact us at
              <a
                href="mailto:support@uuoni.com"
                style={{
                  marginLeft: "0.3rem",
                }}
              >
                support@uuoni.com
              </a>
            </p>
          </InfoContainer>
        )}
      </PageContainer>
    </Layout>
  )
}

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "YYYY MMMM Do")
        contact
      }
      body
      excerpt
      tableOfContents
      timeToRead
      fields {
        slug
      }
    }
  }
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 6rem;
  margin-bottom: 3rem;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1024px) {
    margin-top: 3rem;
  }
`

const Container = styled.div`
  display: flex;

  @media screen and (min-width: 550px) {
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const TOCContainer = styled.div`
  text-align: left;
  margin-top: 1vh;

  @media screen and (min-width: 550px) {
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const TOCContent = styled.div`
  position: sticky;
  top: 10vh;
  width: 25vw;
  max-width: 420px;
  min-width: 260px;

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 1024px) {
    height: 70vh;
    overflow-y: auto;
    margin-right: 20%;
  }
`
// overflow-y: scroll;
// height: 65vh;

const ContentContainer = styled.div`
  text-align: left;
  @media screen and (min-width: 768px) {
    max-width: 67%;
    margin-left: 1.25rem;
    margin-right: 0px;
  }

  @media screen and (max-width: 767px) {
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;

  @media screen and (min-width: 1025px) {
    width: 35%;
  }

  @media screen and (max-width: 767px) {
    width: 80%;
    margin-top: -15%;
  }
`

export default MDXLayout
